import React from "react";
import styled from "styled-components";
import { MOBILE_BREAKPOINT } from "@rimo/ui-old";

interface VideoSectionProps {
  src: string;
  maxWidth: string;
}

const VideoSection = (props: VideoSectionProps) => (
  <VideoSectionWrapper>
    <Video {...props} />
  </VideoSectionWrapper>
);

export const Video: React.FC<{ src: string; maxWidth: string }> = ({ src, maxWidth }) => (
  <VideoWrapper $maxWidth={maxWidth}>
    <IframeWrapper $maxWidth={maxWidth}>
      <VideoIframe
        src={src}
        title="YouTube video player"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      />
    </IframeWrapper>
  </VideoWrapper>
);

const VideoSectionWrapper = styled.div`
  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    margin-top: 3rem;
  }
`;

const VideoWrapper = styled.div<{ $maxWidth: string }>`
  width: 100%;
  max-width: ${({ $maxWidth }) => $maxWidth};
  margin: 0 auto;
`;

const IframeWrapper = styled.div<{ $maxWidth: string }>`
  position: relative;
  aspect-ratio: 16 / 9;
  max-width: ${({ $maxWidth }) => $maxWidth};
`;

const VideoIframe = styled.iframe`
  width: 100%;
  height: 100%;
  border: 0;
  border-radius: 0.5rem;

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    border-radius: 0;
  }
`;

export default VideoSection;
